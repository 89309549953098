<template>
  <a-card :bordered="false">
    <div>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="套盒内商品"
        sub-title=""
        @back="() => $router.go(-1)"
      >
        <a-row type="flex">
          <a-statistic title="" :value="'套盒：' + mdl.name" />
        </a-row>
      </a-page-header>
      <div class="table-page-search-wrapper" v-if="false">
        <a-form layout="inline">
          <a-row :gutter="24">
            <a-col :md="4" :sm="24">
              <a-form-item label="批次">
                <a-input v-model="queryParam.batch" placeholder="请输入批次" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="账册编号">
                <a-input v-model="queryParam.ems_no" placeholder="请输入账册编号" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>

      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 70px; max-width: 70px">
          {{ text }}
        </div>
        <div slot="kg_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 120px">
          {{ text }}
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">编辑</a>
            <a-divider type="vertical"/>
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
      <union-goods-form
        ref="createModal"
        v-if="visible"
        :visible.sync="visible"
        :loading="confirmLoading"
        :model="mdl"
        @cancel="handleAddCancel"
        @ok="handleOk"
      />
      <union-goods-edit-form
        ref="editModal"
        v-if="edit_visible"
        :visible.sync="edit_visible"
        :loading="confirmLoading"
        :model="mdl"
        @cancel="handleEditCancel"
        @ok="handleEditOk"
      />
    </div>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import UnionGoodsForm from '@/views/c-wms/goods/modules/UnionGoodsForm'
import UnionGoodsEditForm from '@/views/c-wms/goods/modules/UnionGoodsEditForm'
import { Base64 } from 'js-base64'

import { union_goods_list, union_goods_create, union_goods_delete, union_goods_update } from '@/api/c_wms_goods'

export default {
  name: 'TableList',
  components: {
    STable,
    UnionGoodsForm,
    UnionGoodsEditForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      edit_visible: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 100,
          fixed: 'left',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '货品名称',
          dataIndex: 'son_goods'
        },
        {
          title: '数量',
          dataIndex: 'son_goods_count',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return union_goods_list(Object.assign(parameter, this.queryParam, { parent_goods_id: this.$route.params.id }))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.mdl = JSON.parse(Base64.decode(this.$route.query.record || ''))
  },
  methods: {
    handleAdd () {
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEditCancel () {
      this.edit_visible = false
      const form = this.$refs.editModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      console.log(record)
      this.current_id = record.id
      this.edit_visible = true
    },
    handleDelete (record) {
      console.log(record.id)
      union_goods_delete(record.id).then((res) => {
        console.log(res)
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.parent_goods_id = this.mdl.id
          union_goods_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleEditOk () {
      const form = this.$refs.editModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          union_goods_update(values, this.current_id).then(res => {
              // 重置表单数据
              form.resetFields()
              // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
            }).finally(() => {
            this.edit_visible = false
            this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>
