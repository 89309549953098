var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建","width":680,"visible":_vm.visible,"confirmLoading":_vm.loading,"maskClosable":false},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"盒内商品"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'son_goods_id',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]),expression:"[\n                'son_goods_id',\n                {\n                  rules: [\n                    { required: true, message: '请选择' },\n                  ],\n                },\n              ]"}],attrs:{"showSearch":"","placeholder":"选择","filter-option":false,"not-found-content":_vm.fetching ? undefined : null,"allow-clear":""},on:{"search":this.corporationSearch,"change":_vm.handleDataChange}},[(_vm.fetching)?_c('a-spin',{attrs:{"slot":"notFoundContent","size":"small"},slot:"notFoundContent"}):_vm._e(),_vm._l((_vm.wms_goods),function(d){return _c('a-select-option',{key:d.id,attrs:{"value":d.id}},[_vm._v(" "+_vm._s(d.name)+" ")])})],2)],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['son_goods_count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['son_goods_count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]"}],attrs:{"min":0}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }