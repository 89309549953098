<template>
  <a-modal
    title="编辑"
    :width="380"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12" v-if="false">
            <a-form-item label="套盒内商品">
              <a-select
                v-decorator="[
                  'son_goods_id',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                showSearch
                placeholder="选择"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="this.corporationSearch"
                @change="handleDataChange"
                allow-clear
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in wms_goods" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="数量" >
              <a-input-number style="width: 100%;" :min="0" v-decorator="['son_goods_count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import debounce from 'lodash/debounce'
import { goods_list } from '@/api/c_wms_goods'

// 表单字段
const fields = ['id', 'son_goods_count']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.corporationSearch = debounce(this.fetchData, 800)
    return {
      fetching: false,
      wms_goods: [],
      form: this.$form.createForm(this)
    }
  },
  created () {
    console.log('custom modal created', this.form)
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loadEditInfo(this.record)
    })
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    fetchData (value) {
      console.log('fetching user', value)
      this.wms_goods = []
      this.fetching = true
      this.wmsGoodsList(value)
    },
    wmsGoodsList (name) {
      goods_list({ is_union: false, name: name }).then(res => {
        this.wms_goods = res.data.entries
      })
    },
    handleDataChange (value) {
      console.log(value)
      if (value === undefined) {
        this.fetchData(value)
      }
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, fields)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
